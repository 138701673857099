import React from "react";



const Lobby = ({meeting_time}) => {
    return(
        <div style={{
            alignItems: 'center',
            textAlign: 'center',
            marginTop: '50px'
        }}>
            <p style={{fontSize: '25px', fontWeight: '50px'}}>
                This meeting is scheduled for {meeting_time}.
                <br/>You can start the meeting 5 mins before scheduled time.
            </p>
        </div>
    )
}

export default Lobby;
