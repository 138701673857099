import { Typography } from 'antd';
import React from 'react';
import dots from "../../../assets/3dots.svg"
import image from "../../../assets/owner.png"
import "./PostOwnerDetails.css"
const PostOwnerDetails = ({postDetail}) => {
    return (
        <div className="mt-3">
        <div className="p-3 d-flex justify-content-between align-items-center">
              <div className='d-flex align-items-center'>
                <img className="profile-image" src={postDetail?.user?.profile?.profile_img_url_full}/>
                <div className='ml-3'>
                    <Typography className='owner-name'>{postDetail?.user?.profile?.full_name}</Typography>
                  
                    <Typography className='n__days_ago'>{postDetail?.formated_created_at}</Typography>
                </div>
               
              </div>
              {/*<div>*/}
              {/*    <img src={dots}/>*/}
              {/*</div>*/}
        </div>
    </div>
    );
};

export default PostOwnerDetails;