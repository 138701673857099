import React, {useEffect, useState} from "react";
import {Button, h1} from "antd"
import './inviteGroup.css'
import PopUpModal from "../PopUpModal/PopUpModal";
import CommonService from "../../apis/CommonService";
import {GET_GROUP_DETAIL, GET_POST_DETAIL} from "../../apis/ApiConstants";
import {useParams} from "react-router-dom";
import {Helmet} from "react-helmet";

const GroupInvite = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [groupDetail, setGroupDetail] = useState();
    const params = useParams();

    useEffect(()=> {
        fetchGroupDetail()
    },[])

    const fetchGroupDetail = async () => {
        // params.id
        const response = await CommonService.get(GET_GROUP_DETAIL, {
            room_id: params.room_id,
        });

        setGroupDetail(response.data)
    }

    const deviceOS = getDeviceOS();
    const AppUrl = () => {

        if(deviceOS === "MacOS" || deviceOS === "iOS") {
            return "https://apps.apple.com/in/app/daakia-daakia-pvt-ltd/id1577764111"
        }else {
            return "https://play.google.com/store/apps/details?id=com.app.daakia"
        }
    }

    function getDeviceOS() {
        const userAgent = window.navigator.userAgent;

        if (userAgent.includes('Win')) {
            return 'Windows';
        } else if (userAgent.includes('Mac')) {
            return 'MacOS';
        } else if (userAgent.includes('Linux')) {
            return 'Linux';
        } else if (userAgent.includes('Android')) {
            return 'Android';
        } else if (userAgent.includes('iOS')) {
            return 'iOS';
        } else {
            return 'Unknown';
        }
    }

    return(
        <div>
            <Helmet>
                <title>Group Invite</title>
                <meta
                    name="description"
                    content="Easy to use secure messaging platform!"
                    data-react-helmet="true"
                />
                <meta property="og:description"
                      content="Easy to use secure messaging platform!" />
                <meta name="description" content="Easy to use secure messaging platform!"/>
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="../../../public/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="../../../public/favicon-16x16.png" />
                <link rel="mask-icon" href="../../../public/safari-pinned-tab.svg" color="#5bbad5" />
                <meta name="apple-mobile-web-app-title" content="daakia" />
                <meta name="application-name" content="daakia" />
                <meta property="og:title" content="Daakia: Bridging Distances" />
                <meta property="og:image" content="https://daakia-xmpp.s3.amazonaws.com/mstile-150x150_1689080948045.png" />
                <meta property="invite_link_type" content="REGULAR" />
                <meta property="invite_link_type_v2" content="REGULAR" />
                <meta name="mobile-web-app-capable" content="yes" />
            </Helmet>
            <h3 style={{textAlign: "center", fontWeight: 600}}>Daakia Group Invite!</h3>
            <div style={{textAlign: 'center'}}>
                <img className={"daak-logo"} src={'https://daakia-xmpp.s3.amazonaws.com/Logo-Transparent_1689057272998.png'}/>
            </div>
            <div className={"group-name-div"}>
            <span className={"group-name"}>{groupDetail?.group_name.val}</span>
            </div>
            <div className={'join-chat-btn-div'}>
            <Button className={'join-chat-btn'}
                    onClick={() => window.location.href=AppUrl()}>
                Download App </Button>
            </div>
            <PopUpModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </div>
    )
}

export default GroupInvite;
