import { Button, Modal } from "antd";
import React from "react";
import popup from "../../assets/popup.png";
import "./PopUpModal.css";
const PopUpModal = ({ modalOpen, setModalOpen }) => {

  const redirectToPlayStore = () => {
    window.location.href='https://play.google.com/store/apps/details?id=com.app.daakia';
  }

  const deviceOS = getDeviceOS();
  const AppUrl = () => {

    if(deviceOS === "MacOS" || deviceOS === "iOS") {
      return "https://apps.apple.com/in/app/daakia-daakia-pvt-ltd/id1577764111"
    }else {
      return "https://play.google.com/store/apps/details?id=com.app.daakia"
    }
  }

  function getDeviceOS() {
    const userAgent = window.navigator.userAgent;

    if (userAgent.includes('Win')) {
      return 'Windows';
    } else if (userAgent.includes('Mac')) {
      return 'MacOS';
    } else if (userAgent.includes('Linux')) {
      return 'Linux';
    } else if (userAgent.includes('Android')) {
      return 'Android';
    } else if (userAgent.includes('iOS')) {
      return 'iOS';
    } else {
      return 'Unknown';
    }
  }

  return (
    <Modal
      title=""
      style={{ top: 30 }}
      open={modalOpen}
      onOk={() => setModalOpen(false)}
      onCancel={() => setModalOpen(false)}
      footer={null}
      header={null}
      width={400}
    >
      <div className="text-center">
        <div className="mt-3">
          <img src={popup} />
          <div className="text-center mt-5 join-section">
            <div>
              <span>Want to see more? </span>
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                // href={'https://play.google.com/store/apps/details?id=com.app.daakia'}
              >
                Join
              </span>
              <span> or </span>
              <span
                style={{
                  fontStyle: "normal",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                Log in
              </span>
              <span> through Daakia app!</span>
              <br />
              <span>It's fun and exciting!</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center join-login">
            <a className="join">
              {" "}
              <span
                  onClick={()=>window.location.href=AppUrl()}
              >Join</span>
            </a>
            <a className="login">
              {" "}
              <span
                  onClick={()=>window.location.href=AppUrl()}
              >Log in</span>
            </a>
          </div>
        </div>
        <div onClick={() => setModalOpen(false)} className="cancel-div">
          <a className="cancel">
            {" "}
            <span>Cancel</span>
          </a>
        </div>
      </div>
    </Modal>
  );
};

export default PopUpModal;
