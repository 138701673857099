// Common
export const SEND_OTP = "api/v2/common/sendOtp";

export const GET_POST_DETAIL = "webApp/post/detail";
export const GET_GROUP_DETAIL = "webApp/group/detail";
export const VALIDATE_MEETING = "meeting/exist";
//To validate host email
export const VALIDATE_MEETING_HOST = "meeting/verifyHost";
export const HOST_JOINING = "saas/meeting/host/join";
export const CHECK_HOST_JOINED_MEETING = "saas/meeting/check/hostJoined";
export const HOST_MEETING_LEAVE = "saas/meeting/update/hostLeave";
export const MEETING_CONFIG = "saas/meeting/config";
export const MEETING_FEATURES = "saas/meeting/features";


export const WEBHOOK_HOST_MEETING_JOINING = "daakmeet/webhook/events/host/join";
