//To convert base64 string
export function encoder(code) {
    return window.btoa(code);
}

//To get base url
export const baseUrlGenerator = (link) => {
    return `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? `:${window.location.port}` : ""
    }${link || ""}`;
};
