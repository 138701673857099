import api from "./ApiInterceptor";

const CommonService = {
  get: async (api_url, params = {}) => {
    return api({
      url: api_url,
      method: "get",
      params,
    });
  },
  getById: async (api_url, id) => {
    return api({
      url: `${api_url}${id}`,
      method: "get",
    });
  },
  searchKeyword: async (api_url, params) => {
    return api({
      url: api_url,
      method: "get",
      params,
    });
  },
  create: async (api_url, data) => {
    return api({
      url: api_url,
      method: "post",
      data,
    });
  },
  update: async (api_url, id, data) => {
    return api({
      url: `${api_url}${id}`,
      method: "put",
      data,
    });
  },

  updateData: async (api_url, data) => {
    return api({
      url: `${api_url}`,
      method: "put",
      data,
    });
  },

  getPaymentLink: async (api_url, params) => {
    return api({
      url: `${api_url}`,
      method: "put",
      params
    });
  },
  updateStatus: async (api_url, id, params) => {
    return api({
      url: `${api_url}${id}`,
      method: "patch",
      params,
    });
  },
  remove: async (api_url, id) => {
    return api({
      url: `${api_url}${id}`,
      method: "delete",
    });
  },
};

export default CommonService;
