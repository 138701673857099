import React, { useEffect, useState } from 'react'
import {Button, Form, Input, Modal} from "antd";
import OtpInput from 'react-otp-input';

const HostEmailValidationModal = ({ visible, close, onFinish }) => {
    const [form] = Form.useForm();
    const [pin, setPin] = useState('')

    const getTitle = () => 'Host Email Validation'
    const getButtonText = () => 'Verify';

    const validateFormElement = () => {
        form
            .validateFields()
            .then(values => {
                form.resetFields();
                onFinish(values);
            })
            .catch(info => {
                console.log('Validate Failed:', info);
            });
    }

    return (
        <Modal
            visible={visible}
            title={getTitle()}
            okText={getButtonText()}
            // cancelText="Discard"
            // onCancel={close}
            footer={
                <Button style={
                    {
                        color: '#F6F6F6',
                        backgroundColor: '#033153',
                        fontWeight: 'bold'
                    }
                } onClick={() => validateFormElement()}>Verify</Button>
            }
            // onOk={() => validateFormElement()}
        >
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
            >
                <Form.Item name='email' label='Enter Host Email'
                           rules={[{ required: true, message: 'Email is required!' }]}
                >
                    <Input placeholder="Enter Email"></Input>
                </Form.Item>

                <Form.Item
                    name="pin"
                    label="Enter pin"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your pin',
                        },
                    ]}>
                    <OtpInput
                        value={pin}
                        onChange={setPin}
                        numInputs={4}
                        renderSeparator={<span> &nbsp; </span>}
                        isInputNum={true}
                        renderInput={(props) => <input {...props} />}
                        inputStyle={{
                            width: "90px",
                            height: "50px",
                            margin: "20px .3rem",
                            fontSize: "2rem",
                            borderRadius: 10,
                            border: 'none',
                            outline: "none",
                            backgroundColor: "#F0F3F7"
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default HostEmailValidationModal;
