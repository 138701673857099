import { Typography } from "antd";
import React, {useEffect, useState} from "react";
import rect from "../../../assets/Rectangle 10213.png";
import "./Post.css";
import PopUpModal from "../../PopUpModal/PopUpModal";
import eyeIcon from "../../../assets/eye.svg"
import likeIcon from "../../../assets/like.svg"
import shareIcon from "../../../assets/share.svg"
import {EnvironmentTwoTone, PlayCircleOutlined} from "@ant-design/icons";

const Post = ({ viewComments, setViewComments, postDetail }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [isSeeMore, setIsSeeMore] = useState(true);
  const [descriptionText, setDescriptionText] = useState("..see more");
  const location = postDetail?.location_info?.split('@',4)
    let media = <></>
    let postContent = <></>
    let postLocation = <></>

    const handelPostDescription = () => {
        if(isSeeMore === true) {
            document.getElementById("post-desc").classList.remove("post-text");
            document.getElementById("post-desc").classList.add("post-description");
            setIsSeeMore(false)
            setDescriptionText("...see less")
        }else if (isSeeMore === false) {
            document.getElementById("post-desc").classList.remove("post-description");
            document.getElementById("post-desc").classList.add("post-text");
            setIsSeeMore(true)
            setDescriptionText("...see more")
        }
    }

    if(postDetail?.story_type === 2) {
         media = (
             <>
                 {
                     postDetail?.post_tagged_users?.length > 0 ?
                         <div className="d-flex px-3">
                             <Typography className="tagged-text">
                                 With
                             </Typography>
                             <Typography className="tagged-user px-1">
                                 {postDetail?.post_tagged_users[0]?.user?.profile?.full_name}
                                 {postDetail?.post_tagged_users?.length > 1 ?
                                     ` ' and ${postDetail?.post_tagged_users?.length - 1}` + " others.": ''}
                             </Typography>
                             {/*{postDetail?.post_tagged_users?.map((ele, i) => (*/}
                             {/*    <Typography className="tagged-user px-1">*/}
                             {/*        {ele?.user?.profile?.full_name + i} {i >= 0 && i !== postDetail?.post_tagged_users?.length - 1 ? ", " : ''}*/}
                             {/*    </Typography>*/}
                             {/*))}*/}

                         </div>
                            :<></>
                 }
            <div className="img-div">
                <img onClick={() => setModalOpen(true)} src={postDetail?.post_media[0]?.media_url} />
            </div>
             </>
        )
    }else if(postDetail?.story_type === 3) {
        media = (
            <>
                {
                    postDetail?.post_tagged_users?.length > 0 ?
                        <div className="d-flex px-3">
                            <Typography className="tagged-text">
                                With
                            </Typography>
                            <Typography className="tagged-user px-1">
                                {postDetail?.post_tagged_users[0]?.user?.profile?.full_name}
                                {postDetail?.post_tagged_users?.length > 1 ?
                                    ` ' and ${postDetail?.post_tagged_users?.length - 1}` + " others.": ''}
                            </Typography>
                            {/*{postDetail?.post_tagged_users?.map((ele, i) => (*/}
                            {/*    <Typography className="tagged-user px-1">*/}
                            {/*        {ele?.user?.profile?.full_name + i} {i >= 0 && i !== postDetail?.post_tagged_users?.length - 1 ? ", " : ''}*/}
                            {/*    </Typography>*/}
                            {/*))}*/}

                        </div>
                        :<></>
                }
            <div className="vide-div" onClick={() => setModalOpen(true)}>
            <video onClick={() => setModalOpen(true)} controls>
                <source src={postDetail?.post_media[0]?.media_url}/>
                <PlayCircleOutlined style={{zIndex: 1}} />
            </video>
        </div>
            </>

        )
    }

    if(postDetail?.post_feeling) {
        postContent = (
           <>
           <div className="feeling-div px-2 d-flex align-items-center">
            <img onClick={() => setModalOpen(true)} src={postDetail?.post_feeling?.img_url} />
            <Typography className="feeling-text">
                Feeling {postDetail?.post_feeling?.title}{" "}
            </Typography>
        </div>
               { postDetail?.story_type !== 3 && postDetail?.story_type !== 2 ?
               <div className="d-flex px-3">
                   <Typography className="tagged-text">
                       With
                   </Typography>
                   <Typography className="tagged-user px-1">
                       {postDetail?.post_tagged_users[0]?.user?.profile?.full_name}
                       {postDetail?.post_tagged_users?.length > 1 ?
                           ` ' and ${postDetail?.post_tagged_users?.length - 1}` + " others.": ''}
                   </Typography>
                   {/*{postDetail?.post_tagged_users?.map((ele, i) => (*/}
                   {/*    <Typography className="tagged-user px-1">*/}
                   {/*        {ele?.user?.profile?.full_name + i} {i >= 0 && i !== postDetail?.post_tagged_users?.length - 1 ? ", " : ''}*/}
                   {/*    </Typography>*/}
                   {/*))}*/}

               </div>
                   :<></>
               }
               {postDetail?.location_info ?
            <div>
                <Typography className="location-text justify-content-between d-flex px-2">
                    <EnvironmentTwoTone className="location-icon px-1"/>
                    {location[location?.length - 1]}{" "}
                </Typography>
            </div>
            : <></> }
    </>
       )
    }

    if(postDetail?.post_activity) {
         postContent = (
            <>
            <div className="feeling-div px-2 d-flex align-items-center">
                <img onClick={() => setModalOpen(true)} src={postDetail?.post_activity?.img_url} />
                <Typography className="feeling-text">
                    {postDetail?.post_activity?.title}{" "}
                </Typography>
            </div>
                { postDetail?.story_type !== 3 && postDetail?.story_type !== 2 ?
                    <div className="d-flex px-3">
                        <Typography className="tagged-text">
                            With
                        </Typography>
                        <Typography className="tagged-user px-1">
                            {postDetail?.post_tagged_users[0]?.user?.profile?.full_name}
                            {postDetail?.post_tagged_users?.length > 1 ?
                                ` ' and ${postDetail?.post_tagged_users?.length - 1}` + " others.": ''}
                        </Typography>
                        {/*{postDetail?.post_tagged_users?.map((ele, i) => (*/}
                        {/*    <Typography className="tagged-user px-1">*/}
                        {/*        {ele?.user?.profile?.full_name + i} {i >= 0 && i !== postDetail?.post_tagged_users?.length - 1 ? ", " : ''}*/}
                        {/*    </Typography>*/}
                        {/*))}*/}

                    </div>
                    :<></>
                }                {
                    postDetail?.location_info ?
                    <div>
                        <Typography className="location-text justify-content-between d-flex px-2">
                            <EnvironmentTwoTone className="location-icon px-1"/>
                            {location[location?.length - 1]}{" "}
                        </Typography>
                    </div>
                    :
                        <></>
                }
            </>
        )
    }

  return (
    <div className="mt-1">
        {postContent}
        {media}

      <div className="p-3">
          {
              postDetail?.post_description ? <Typography id={"post-desc"} className="post-text">
                  {postDetail?.post_description}{" "}
              </Typography>
              : <></>
          }
          {
              postDetail?.post_description && postDetail?.post_description.length > 45 ?
              <Typography onClick={()=> handelPostDescription()} className="text-right see-more">{descriptionText}</Typography>
                : <></>
          }

      </div>
      <div className="like_view_comment d-flex justify-content-between align-items-center px-3">
        <div className="d-flex align-items-center">
          {" "}
          <div className="like-button mr-1">
            <img src={likeIcon} className="like-icon" />
          </div>
            {postDetail.total_reactions_count}
        </div>
        <div className="d-flex align-items-center total-view">
          {" "}
          <img src={eyeIcon} className="eye-icon" />
            {postDetail.total_views_count} Views
        </div>
        <div
          style={{
            cursor: "pointer",
          }}
          onClick={() => setViewComments(!viewComments)}
        >
            {postDetail.total_comments_count} Comments
        </div>
      </div>
      <div className="share mt-2 d-flex justify-content-around align-items-center">
        <div onClick={() => setModalOpen(true)} className="d-flex align-items-center share-text">
          {" "}
          <img src={shareIcon} className="mr-2" /> Share
        </div>
        <Typography onClick={() => setModalOpen(true)} className="see-post-on">See this post on Daakia</Typography>
      </div>
      <div className="rect-img">
        <img style={{ width: '100%' }} src={rect} />
      </div>
      <PopUpModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Post;
