import { CaretLeftOutlined, LikeFilled, LikeOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React, {useState} from "react";
import "./Comments.css";
import PopUpModal from "../../PopUpModal/PopUpModal";
const Comment = ({ value }) => {
  const { comment, created_at_formatted, react, user } = value;
    const [modalOpen, setModalOpen] = useState(false);

    return (
    <div className="d-flex mt-3 comment-div">
      <div>
        <img className="profile-image" src={user?.profile?.profile_img_url_full} />
      </div>
      <div className="ml-3">
        <Typography className="name">{user?.profile?.full_name}</Typography>
        <Typography className="date">{created_at_formatted}</Typography>
        <Typography className="comment">{comment}</Typography>
        <div className="rects d-flex  align-items-center mt-2 mb-2">
         
            <div onClick={() => setModalOpen(true)} className="d-flex align-items-center ">
              {" "}
              <LikeOutlined className="liked-icon mr-1" />{" "}
                {react} React
            </div>
         
          <div className="ml-4 pointer">
            <div onClick={() => setModalOpen(true)} className="d-flex align-items-center">
              {" "}
              <CaretLeftOutlined className="reply-icon mr-1" /> Reply
            </div>
          </div>
        </div>
      </div>
        <PopUpModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default Comment;
