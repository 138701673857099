import React, {useEffect, useState} from "react";
import {Button} from "antd";


const Demo = () => {

    const [showIframe, setShowIframe] = useState(false)

    useEffect(() => {
        window.addEventListener('message', (event) => {
            if (event.data === 'closeIframe') {
                // Remove or hide the iframe element
                setShowIframe(false); // Assuming you're using a state variable to control visibility
            }
        });
    }, []);

    return(
        <div style={{height: "100%", width: "100%"}}>
            <h1>hey</h1>
            <Button style={{backgroundColor: "#ce4949"}} title={"govind"} onClick={() => setShowIframe(!showIframe)}>hey test here</Button>
            {
                showIframe ? <iframe
                    title="Iframe Content"
                    width="100%"
                    height="400px"
                    src={'http://localhost:3001/hello/'}
                    frameBorder="0"
                /> : <></>
            }

        </div>
    )
}

export default Demo;
