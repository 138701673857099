import React from "react";
import {Button} from "antd";



const Lobby = ({meeting_time}) => {
    return(
        <div style={{
            alignItems: 'center',
            textAlign: 'center',
            marginTop: '50px'
        }}>
            <p style={{fontSize: '25px', fontWeight: '50px'}}>
                This meeting is scheduled for {meeting_time}.
                <br/>Please wait for the host to start the meeting
            </p>

            <Button style={{margin: "5px", backgroundColor: "#033153", color: "#FFFFFF"}}
            onClick={() => window.location.reload()}>Refresh</Button>
        </div>
    )
}

export default Lobby;
