import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import Comments from "./Comments/Comments";
import Post from "./Post/Post";
import "./PostBody.css";
import PostOwnerDetails from "./PostOwnerDetails.js/PostOwnerDetails";
import CommonService from "../../apis/CommonService";
import {GET_POST_DETAIL} from "../../apis/ApiConstants";
import PopUpModal from "../PopUpModal/PopUpModal";
import {Helmet} from "react-helmet";

const PostBody = () => {
    const [viewComments, setViewComments] = useState(false);
    const [postDetail, setPostDetail] = useState({});
    const [modalOpen, setModalOpen] = useState(false);

    const params = useParams();

    useEffect(()=> {
        fetchPostDetail()
    },[])
    const fetchPostDetail = async () => {
        // params.id
        const response = await CommonService.get(GET_POST_DETAIL, {
            id: params.id,
        });

        setPostDetail(response.data)
    }
  return (
    <div>
        <Helmet>
            <title>Daakia: Bridging Distances</title>
            <meta property="og:description"
                  content="Secure social media to share your memories!" />
            <meta name="description"
                  content="Daakia: Bridging Distances"
                  data-react-helmet="true"
            />
            <link rel="apple-touch-icon" href="%PUBLIC_URL%/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="../../../public/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="../../../public/favicon-16x16.png" />
            <link rel="mask-icon" href="../../../public/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="apple-mobile-web-app-title" content="daakia" />
            <meta name="application-name" content="daakia" />
            <meta property="og:title" content="Daakia: Bridging Distances" />
            <meta property="og:image" content="https://daakia-xmpp.s3.amazonaws.com/mstile-150x150_1689080948045.png" />
            <meta property="invite_link_type" content="REGULAR" />
            <meta property="invite_link_type_v2" content="REGULAR" />
            <meta name="mobile-web-app-capable" content="yes" />
        </Helmet>
      <div className="text-center mt-3">
        <div className="title_kp">
          <span>Join {postDetail?.user?.profile?.full_name} and many more on Daakia!</span>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2">
          <div className="title_kr">
            <a onClick={() => setModalOpen(true)} href="#">Download</a>
          </div>
          <span className="title_kr_right ml-2">
            {" "}
            the app now and stay connected!
          </span>
        </div>
      </div>
      <PostOwnerDetails postDetail={postDetail}/>
      <Post viewComments={viewComments} setViewComments={setViewComments} postDetail={postDetail}/>
      {
        viewComments &&
        <Comments postDetail={postDetail}/>
      }

      <div className="text-center mt-4 join-section">
        <div>
          <span>Want to see more? </span>
          <span
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              color: "rgba(87,89,233,1)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(true)}
          >
            Join
          </span>
          <span> or </span>
          <span
            style={{
              fontStyle: "normal",
              fontWeight: "bold",
              color: "rgba(44,185,176,1)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setModalOpen(true)}
          >
            Log in
          </span>
          <span> through Daakia app!</span>
          <span>It's fun and exciting!</span>
        </div>
      </div>
        <PopUpModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
    </div>
  );
};

export default PostBody;
