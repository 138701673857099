import { Typography } from 'antd';
import React from 'react';
import "./Comments.css"
import jack from "../../../assets/jack.png";
import rose from "../../../assets/rose.png";
import Comment from './Comment';
const Comments = ({postDetail}) => {
    const comments = [
        {   
            id:1,
            name:"Jack Johnes",
            avater:jack,
            date: "20 May 2020",
            comment:" Nice to see you guys together having fun. Enjoy your day guys",
            react: 2
        },
        {   
            id:2,
            name:"Rose Dawson",
            avater:rose,
            date: "23 Apr 2020",
            comment:"Yeah! It’s cool to have fun with friends",
            react: 1
        },
        {   
            id:3,
            name:"Rose Dawson",
            avater:rose,
            date: "23 Apr 2020",
            comment:"Yeah! It’s cool to have fun with friends",
            react: "",
        },
    ]
    return (
        <div className='mt-2 pl-3 pr-3'>
            <Typography className='comments-title'>Comments</Typography>
            {
                postDetail?.send_story_comments?.map((ele) =>
                    <Comment key={ele.id} value={ele}/>)
            }
        </div>
    );
};

export default Comments;