import './App.css';
import 'antd/dist/reset.css';
import Header from './Components/Header/Header';
import {Route, Routes, useLocation} from "react-router-dom";
import PostBody from "./Components/PostBody/PostBody";
import GroupInvite from "./Components/InviteGroup/index";
import MeetingInvite from "./Components/MeetingInvite/index";
import HostMeetingInvite from "./Components/HostMeetingInvite/index";
import Demo from "./Components/Demo/index";
import Hello from "./Components/Hello/index";


function App() {
    const location = useLocation();
    const excludeHeaderRoutes = [
        "/sharedmeet",
        "/sharedmeet/host/"
    ];

    const shouldRenderHeader = !excludeHeaderRoutes.some(route => location.pathname.startsWith(route));
    const isMainDivClassNeeded = !excludeHeaderRoutes.some(route => location.pathname.startsWith(route));

    return (
      // <>
    <div className={isMainDivClassNeeded ? "main-div" : ""}>
        {shouldRenderHeader && <Header />}
        <Routes>
            <Route path="/post/:jid/:id" element={<PostBody />} />
            <Route path="/groupinvite/:room_id" element={<GroupInvite />} />
            <Route path="/demo" element={<Demo />} />
            <Route path="/hello" element={<Hello />} />
            <Route path="/sharedmeet/:meeting_id" element={<MeetingInvite />} />
            <Route path="/sharedmeet/host/:meeting_id" element={<HostMeetingInvite />} />
        </Routes>
        {/*</Header>*/}
    </div>
          // <Routes>
          //
          // </Routes>
      // </>
  );
}

export default App;
