import axios from "axios";
// import history from "../history";

import { AUTH_TOKEN } from "../constants";
import { SERVICE_URL, DEFAULT_PATHS } from "../config";
import { notification } from 'antd';

// console.log("SERVICE_URL", SERVICE_URL);
const service = axios.create({
  baseURL: SERVICE_URL,
  timeout: 600000,
});

// Config
const ENTRY_ROUTE = DEFAULT_PATHS;
// const INTERNAL_SERVER_ERROR_ROUTE = "/auth/error-2";
const TOKEN_PAYLOAD_KEY = "secret";

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    // if (!jwtToken) {
    // 	history.push(ENTRY_ROUTE)
    // 	window.location.reload();
    // }

    return config;
  },
  (error) => {
    // Do something with request error here

    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: "",
    };
    if (error && error.response) {
      // Remove token and redirect
      if (
        (error.response.status === 401 &&
          error.response.data.code === "UNAUTHORIZED") ||
        error.response.status === 403
      ) {
        notification.error(error.response.data.message);
        localStorage.removeItem("auth-token");
        // history.push(ENTRY_ROUTE);
        window.location.reload();
      }

      if (error.response.status === 404) {
        notificationParam.message = "Not Found";
        notification.error(error.response.data.message);
      }

      if (error.response.status === 400) {
        notificationParam.message = error.response.data.message
        notification.error(notificationParam)
      }

      if (error.response.status === 500) {
        // history.push(INTERNAL_SERVER_ERROR_ROUTE);
        window.location.reload();
      }

      if (error.response.status === 508) {
        notificationParam.message = "Time Out";
      }
    }

    return Promise.reject(error);
  }
);

export default service;
