import React, {useEffect, useState} from "react";
import {Button} from "antd";


const Hello = () => {

        const closeIframe = () => {
            // Send a message to the parent iframe to request closing
            window.parent.postMessage('closeIframe', '*');
        };




    const [showIframe, setShowIframe] = useState(false)

    return(
        <div style={{height: "100%", width: "100%"}}>
            <h1>hey iframe</h1>
            <Button style={{backgroundColor: "#5f6678"}} onClick={closeIframe}>close</Button>
        </div>
    )
}

export default Hello;
