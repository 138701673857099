
import React from 'react';
import "./Header.css"
import logo from "../../assets/Daakia.svg"
const Header = () => {
    return (
        <div className='header-top'>
            <img src={logo} alt="Daakia"/>
        </div>
    );
};

export default Header;